/* eslint-disable max-len */

const Box = {
  '@type': '@builder.io/sdk:Element',
};

/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
export default {
  name: 'uniqueCreations',
  friendlyName: 'Homepage - Unique Creations',
  canHaveChildren: true,
  // shows options when editing children
  broadcast: true,
  defaultStyles: {
    display: 'inherit',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F63ca5d7a969a44beb694780dad826b53',
  inputs: [
    {
      type: 'list',
      name: 'images',
      defaultValue: [
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F0fbfe95d657b4ecfa27b8a7c82f795fc',
          title: 'A wooden quad-copter made on a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F8c2d27f7591944118f2ff4d367279d65',
          title: 'A leather satchel bag made on a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fe2dfaaf844f04f568f7cb30d5f283582',
          title:
            'Colorful macarons with unique, personalized names cut into them using a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fd1deccd055fa490c974a9feb6b31f54b',
          title:
            'An intricately decorated mandala laser engraved on the back of a laptop computer using a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F6275af02780b4e0480caf3cea8f4f7a9',
          title: 'An art-deco style lamp made from wood cut on a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F8af1fd142ede42ae99da794091d400b6',
          title:
            'A cute little A-frame model made with laser-cut acrylic and laser-engraved wood.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F3b18d5daecac48b9b91cd919251b411f',
          title:
            'Several leather key-chains and jewelry pieces cut and engraved using a Glowforge.',
          blocks: [Box],
        },
        {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fe62a4ce8f450479d84eca94290b28e2c',
          title:
            'A slate coaster with an adorable cow, pig, and chicken etched using a Glowforge laser.',
          blocks: [Box],
        },
      ],
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp'],
        },
        {
          name: 'title',
          friendlyName: 'Title/Alt text for image',
          type: 'string',
        },
        {
          name: 'url',
          friendlyName: 'Link',
          type: 'url',
        },
        {
          name: 'hoverState',
          helperText: 'Show content blocks on hover.',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'blocks',
          type: 'blocks',
          hideFromUI: true,
          helperText:
            'This is an editable region where you can drag and drop blocks.',
          defaultValue: [Box],
        },
      ],
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      // class so that desktop can select the link
      class: 'unique-creations-expanded-link',
      component: {
        name: 'Glowforge:Button',
        options: {
          variant: 'primary',
          text: 'See what people are making',
          iconSize: 24,
          addIcon: true,
          chooseCommonIcon:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb08ad2d1eee44230b8f3675858105e12',
          link: 'https://glowforge.com/discover',
        },
      },
      responsiveStyles: {
        large: {
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          flexShrink: '0',
          boxSizing: 'border-box',
        },
      },
    },
  ],
};
